
.sitemap-text{
  .size{
    &--normal18{
      font-size: 1.8em;
    }
    &--large25{
      font-size: 2.5em;
    }
    &--large35{
      font-size: 3.5em;
    }
  }
  .level1{
    padding-left: 20px;
  }
  .level2{
    padding-left: 40px;
  }
  .level3{
    padding-left: 60px;
  }
  .level4{
    padding-left: 80px;
  }
  .weight600{
    font-weight: 600;
  }
  .weight400{
    font-weight: 400;
  }
  .weight300{
    font-weight: 300;
  }

  .uppercase{
    text-transform: uppercase;
  };
  .underline{
    text-decoration: underline;
  }

  a{
    color: black;
    &:hover{
      color: grey;

    }
  }
  .sitemap-row{
    &:hover{
      background-color: whitesmoke;
    }
  }
}