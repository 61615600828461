@import "../../../../../themes/static/themes/css/settings/settings";


@mixin menu_align_for_breakpoints($classname, $classname_after, $prop, $value) {

  #{$classname} {
    &#{$classname_after} {
      #{$prop}: #{$value};
    }
  }

  @each $breakpoint in $breakpoint-classes {
    @if $breakpoint != large {
      #{$classname}{
        &#{$classname_after}-#{$breakpoint} {
          @include breakpoint($breakpoint only) {
            #{$prop}: #{$value} !important;
          }
        }
      }
    }
  }
}

@mixin menu_vertical_align_for_breakpoints($classname, $classname_after, $prop, $value) {

  #{$classname} #{$classname_after} {
    #{$prop}: #{$value};
  }

  @each $breakpoint in $breakpoint-classes {
    @if $breakpoint != large {
      #{$classname}-#{$breakpoint} #{$classname_after} {
        @include breakpoint($breakpoint only) {
          #{$prop}: #{$value} !important;
        }
      }
    }
  }
}

.menu-manager{
  &.dropdown{
    align-items: center;
    &.vertical{
      align-items: initial;
    }
  }
}
@include menu_align_for_breakpoints('.menu-manager', '.mm-align-left', justify-content, left);
@include menu_align_for_breakpoints('.menu-manager', '.mm-align-center', justify-content, center);
@include menu_align_for_breakpoints('.menu-manager', '.mm-align-right', justify-content, flex-end);

.menu.vertical li a {
  text-align: left;
}

.menu.vertical{
  @include menu_vertical_align_for_breakpoints('&.mm-align-left', '', align-items, flex-start);
  @include menu_vertical_align_for_breakpoints('&.mm-align-center', '', align-items, center);
  @include menu_vertical_align_for_breakpoints('&.mm-align-right', '', align-items, flex-end);
}

