//.magazine-det{
//  &__img{}
//  &__intro{
//    @include breakpoint(large) {
//      padding: 50px;
//
//      h1{
//        margin-top: 50px;
//      }
//      .payoff{
//        margin-top: 50px;
//        font-size: 20px;
//      }
//    }
//  }
//  &__paragraphs{
//    // Css rules to style old article's paragraphs
//    .news-detail__paragraph{
//      align-items: center;
//    }
//  }
//  &__related{
//    .related-item{
//      &__content{
//        @include breakpoint(large) {
//          padding: 35px;
//          display: flex;
//          flex-direction: column;
//          justify-content: space-between;
//          align-items: flex-start;
//          height: 100%;
//        }
//      }
//      &__title-w{
//        @include breakpoint(large) {
//          display: flex;
//          flex-direction: row;
//          justify-content: space-between;
//          align-items: center;
//          width: 100%;
//          h4{
//            flex-basis: 75%;
//          }
//        }
//      }
//      &__abstract{}
//      &__read-more{}
//    }
//  }
//}
//
//.descr-box{
//  background-color: #282828;
//  @include breakpoint(large) {
//    padding-top: 70px;
//    padding-bottom: 70px;
//  }
//  &__content{
//    border-left: 10px solid white;
//    padding-left: 40px;
//    color: #ffffff;
//    font-size: 20px;
//    & > *, a {
//      color: #ffffff;
//    }
//  }
//  &--mb{
//    margin-bottom: 35px;
//  }
//}
//
//// Width for article body content, different from site width for aesthetic purposes
//.magazine-container-width{
//  @include breakpoint(large) {
//    width: 70%;
//    margin: 0 auto;
//  }
//}
.blog-detail {
  .box-title {
    height: 220px;
    display: flex;
  }

  .article-title {
    display: flex;
    align-items: flex-end;
  }

  .categories-article {
    display: flex;
  }

  .article-date {
    display: flex;
    justify-content: flex-end;
  }

  .related-image {
    height: 200px;
    background-size: cover;
  }

  .box-related {
    border: solid;
    border-width: 1px;
    border-color: #e2e2e2;
  }
  .article-image{
  }

}
;@import "sass-embedded-legacy-load-done:69";