@import '../../../themes/static/themes/css/mixins/mixins';

.banner-text-anim {
  position: relative;

  &--height600 {
    position: relative;
    height: 600px;
  }

  &--height550 {
    position: relative;
    height: 550px;
  }

  &--height450 {
    position: relative;
    height: 450px;
  }

  //background-color: white ;
}

.animation-width {
  &--center {
    @include breakpoint(small) {
      width: 80vw;
    }
    @include breakpoint(large) {
      width: 37vw;
    }
  }

  &--start {
    @include breakpoint(small) {
      width: 100vw;
    }
    @include breakpoint(medium) {
      width: 70vw;
    }
    @include breakpoint(large) {
      width: 70vw;
    }
  }
}

.sup {
  position: absolute;
}

.text-an {
  z-index: 10;
  position: absolute;
  width: 100%;
  top: 50%;

  @include breakpoint(small) {
    top: 50%;
    font-size: 2em;
    transform: translate(0%, -100%);
  }
  @include breakpoint(medium) {
    font-size: 2em;
    top: 40%;
    transform: translate(0%, -100%);
  }
  @include breakpoint(large) {
    font-size: 2em;
    top: 35%;
    //transform: translate(0%, 0%);
  }

  p {
    margin-bottom: 0rem;
    line-height: 1.2;
  }

  .normal-text {
    display: block;
    font-weight: 600;
    font-size: 1.5em;
    color: black;
    margin-left: 30px;
    @include breakpoint(large) {
      display: inline;
      font-size: 2em;
    }
  }

  .effect-text {
    display: inline-block;
    vertical-align: top;
    margin: 0;
  }

  .word {
    position: absolute;
    width: 100%;
    //font-size: 2em;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0;
    margin-left: 30px;
    @include breakpoint(small) {
      font-size: 1.5em;
    }
    @include breakpoint(medium) {
      font-size: 1.5em;
    }
    @include breakpoint(large) {
      font-size: 2em;
    }
  }

  .letter {
    display: inline-block;
    position: relative;
    float: left;
    transform: translateZ(25px);
    transform-origin: 50% 50% 25px;
  }

  .letter.out {
    transform: rotateX(90deg);
    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .letter.behind {
    transform: rotateX(-90deg);
  }

  .letter.in {
    transform: rotateX(0deg);
    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .white {
    color: white;
  }

  .wisteria {
    color: #8e44ad;
  }

  .belize {
    color: #2980b9;
  }

  .pomegranate {
    color: #c0392b;
  }

  .green {
    color: #16a085;
  }

  .midnight {
    color: #2c3e50;
  }

  .grey {
    color: grey;
  }

  .red-pleiadi {
    color: #bf1543;
  }

  .blue-pleiadi {
    color: #bf1543;
  }

  .effect {
    &--left {
      text-align: left;
    }

    &--center {
      text-align: center;
    }
  }

  &.pos-absolute {
    &--right {
      text-align: right;
    }

    &--top0 {
      @include breakpoint(large) {
        top: 0%;
        transform: translate(0%, 0%);
      }

    }

    &--top50 {
      @include breakpoint(large) {
        top: 50%;
        transform: translate(0%, -50%);
      }
    }

    &--top100 {
      @include breakpoint(large) {
        top: 100%;
        transform: translate(0%, 0%);
      }
    }

    &--left0 {
      @include breakpoint(large) {
        left: 0%;
      }
    }

    &--left50 {
      @include breakpoint(large) {
        left: 50%;
      }
    }

    &--left100 {
      @include breakpoint(large) {
        left: 100%;
      }
    }

    &--top50left50 {
      @include breakpoint(large) {
        top: 50%;
        left: 70%;
        transform: translate(-35%, -50%);
      }
      @include breakpoint(small) {
        top: 50%;
        left: 5%;
        transform: translate(0%, -50%);
      }

    }
  }
}

