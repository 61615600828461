.blog-index__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 190px;
}
@media print, screen and (min-width: 40em) {
  .blog-index__info {
    min-height: 210px;
  }
}
@media print, screen and (min-width: 64em) {
  .blog-index__info {
    height: 100%;
  }
}
.blog-index__intro {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .blog-index__intro {
    padding-top: 1.25rem;
  }
}
@media print, screen and (min-width: 64em) {
  .blog-index__intro {
    padding-top: 1.875rem;
  }
}
@media screen and (min-width: 90em) {
  .blog-index__intro {
    padding-top: 1.875rem;
  }
}
@media screen and (min-width: 120em) {
  .blog-index__intro {
    padding-top: 2.5rem;
  }
}
@media print, screen and (min-width: 40em) {
  .blog-index__intro {
    padding-bottom: 1.25rem;
  }
}
@media print, screen and (min-width: 64em) {
  .blog-index__intro {
    padding-bottom: 1.875rem;
  }
}
@media screen and (min-width: 90em) {
  .blog-index__intro {
    padding-bottom: 1.875rem;
  }
}
@media screen and (min-width: 120em) {
  .blog-index__intro {
    padding-bottom: 2.5rem;
  }
}
.blog-index__highlights {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .blog-index__highlights {
    padding-top: 1.25rem;
  }
}
@media print, screen and (min-width: 64em) {
  .blog-index__highlights {
    padding-top: 1.875rem;
  }
}
@media screen and (min-width: 90em) {
  .blog-index__highlights {
    padding-top: 1.875rem;
  }
}
@media screen and (min-width: 120em) {
  .blog-index__highlights {
    padding-top: 2.5rem;
  }
}
@media print, screen and (min-width: 40em) {
  .blog-index__highlights {
    padding-bottom: 1.25rem;
  }
}
@media print, screen and (min-width: 64em) {
  .blog-index__highlights {
    padding-bottom: 1.875rem;
  }
}
@media screen and (min-width: 90em) {
  .blog-index__highlights {
    padding-bottom: 1.875rem;
  }
}
@media screen and (min-width: 120em) {
  .blog-index__highlights {
    padding-bottom: 2.5rem;
  }
}
.blog-index__highlights h2 {
  margin: 20px 0 30px 0;
}
@media print, screen and (min-width: 64em) {
  .blog-index__highlights h2 {
    margin: 20px 0 20px 0;
  }
}
@media print, screen and (min-width: 64em) {
  .blog-index__cats .categories-menu {
    justify-content: flex-end;
  }
}
@media print, screen and (min-width: 64em) {
  .blog-index__articles-list {
    padding-left: 0;
    padding-right: 0;
  }
}
.blog-index .highlighted-item__img {
  margin-bottom: 14px;
}
.blog-index .highlighted-item__title h3 {
  font-size: 1.5rem;
}
.blog-index .highlighted-item__descr p {
  font-size: 0.9em;
  line-height: 1.6em;
}

.mag-item {
  margin-bottom: 24px;
}
@media print, screen and (min-width: 64em) {
  .mag-item {
    margin-bottom: 4px;
  }
}
.mag-item__img {
  overflow: hidden;
}
.mag-item__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  background-color: #ffffff;
  transition: background-color 200ms;
}
@media print, screen and (min-width: 64em) {
  .mag-item__content {
    padding: 50px 25px 30px 25px;
  }
}
.mag-item__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
@media print, screen and (min-width: 64em) {
  .mag-item__title h3 {
    max-width: 75%;
  }
}
.mag-item__abstract p {
  margin-bottom: 0;
}
.mag-item:hover .mag-item__content {
  background-color: #f9f9f9;
}
.mag-item--evidence .mag-item__content {
  background-color: #f7f7f7;
}

.tags-cloud {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: 1.5rem;
}
.tags-cloud .tag {
  display: block;
  font-size: 12px;
  padding: 0.125rem 0.25rem;
  text-decoration: none;
  position: relative;
}
.tags-cloud .tag:after {
  position: static;
  margin: 0;
  display: block;
  content: "";
  border-bottom: solid 1px #0a0a0a;
  transform: scaleX(0);
  transition: transform 300ms ease-in-out;
  transform-origin: 0 50%;
}
.tags-cloud .tag:hover:after {
  transform: scaleX(1);
}
.tags-cloud .tag--1 {
  font-size: 0.9375rem;
}
.tags-cloud .tag--2 {
  font-size: 1.125rem;
}
.tags-cloud .tag--3 {
  font-size: 1.3125rem;
}
.tags-cloud .tag--4 {
  font-size: 1.5rem;
}
.tags-cloud .tag--5 {
  font-size: 1.6875rem;
}
.tags-cloud .tag--6 {
  font-size: 1.875rem;
}
.tags-cloud .tag--7 {
  font-size: 2.0625rem;
}
.tags-cloud .tag--8 {
  font-size: 2.25rem;
}
.tags-cloud .tag--9 {
  font-size: 2.4375rem;
}
.tags-cloud .tag--10 {
  font-size: 2.625rem;
}
.tags-cloud .tag.on {
  color: #0a0a0a;
  background-color: #ffffff;
}

.blog-index .button-list {
  display: flex;
}
.blog-index .button-blog {
  padding-right: 15px;
}
.blog-index .categories-article {
  display: flex;
}
.blog-index .article-date {
  display: flex;
  justify-content: flex-end;
}
.blog-index .article-image {
  height: 250px;
  width: auto;
  background-size: cover;
}

.blog-detail .box-title {
  height: 220px;
  display: flex;
}
.blog-detail .article-title {
  display: flex;
  align-items: flex-end;
}
.blog-detail .categories-article {
  display: flex;
}
.blog-detail .article-date {
  display: flex;
  justify-content: flex-end;
}
.blog-detail .related-image {
  height: 200px;
  background-size: cover;
}
.blog-detail .box-related {
  border: solid;
  border-width: 1px;
  border-color: #e2e2e2;
}