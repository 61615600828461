.blog-index{

  &__info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 190px;
    @include breakpoint(medium) {
      min-height: 210px;
    }
    @include breakpoint(large) {
      height: 100%;
    }
  }

  &__intro{
    @include padder(top, 1);
    @include padder(bottom, 1);
  }
  &__highlights{
    @include padder(top, 1);
    @include padder(bottom, 1);
    h2{
      margin: 20px 0 30px 0;
      @include breakpoint(large) {
        margin: 20px 0 20px 0;
      }
    }
  }

  &__cats{
    .categories-menu{
      @include breakpoint(large) {
        justify-content: flex-end;
      }
    }

  }

  &__articles-list{
    @include breakpoint(large) {
      padding-left: 0;
      padding-right: 0;
    }
  }


  .highlighted-item{
    &__img{
      margin-bottom: 14px;
    }
    &__title{
      h3{
        font-size: rem-calc(24);
      }
    }
    &__descr{
      p{
        font-size: 0.9em;
        line-height: 1.6em;
      }
    }
  }
}

.mag-item{
  margin-bottom: 24px;
  @include breakpoint(large) {
    margin-bottom: 4px;
  }

  &__img{
    overflow: hidden;
  }
  &__content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    background-color: #ffffff;
    transition: background-color 200ms;
    @include breakpoint(large) {
      padding: 50px 25px 30px 25px;
    }
  }
  &__title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    h3{
      @include breakpoint(large) {
        max-width: 75%;
      }
    }
  }
  &__abstract{
    p{
      margin-bottom: 0;
    }
  }

  &:hover{
    .mag-item__content{
      background-color: #f9f9f9;
    }
  }

  &--evidence{
    // First item in list, with slight different template
    .mag-item__content{
      background-color: #f7f7f7;
    }
  }
}

// Style for tag cloud, now keep it outside
.tags-cloud{
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    line-height: 1.5rem;
  .tag{
    //color: #a33;
    display: block;
    font-size: 12px;
    padding: 0.125rem 0.25rem;
    text-decoration: none;
    position: relative;

    @include menu-link-fx-border();
    @for $i from 1 through 10 {
      &--#{$i} {
        font-size: rem-calc(12 + ($i*3));
      }
    }
    &.on {
      color: $black;
      background-color: $white;
    }
  }
}

.blog-index{
  .button-list{
    display: flex;
  }
  .button-blog{
    padding-right:15px ;
  }
  .categories-article{
    display: flex;
  }
  .article-date{
    display: flex;
    justify-content: flex-end;
  }
  .article-image{
    height:250px;
    width: auto;
    background-size: cover;
  }
}
;@import "sass-embedded-legacy-load-done:67";