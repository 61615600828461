@import '../../../../../node_modules/foundation-sites/scss/util/util';


@mixin headers() {
  @for $i from 1 through 6 {
    h#{$i} {
      @content;
    }
  }
}

@mixin ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin transition($prop: all, $duration: 200ms, $timing: ease-in-out, $delay: 0ms) {
  transition: {
    property: $prop;
    duration: $duration;
    timing-function: $timing;
    delay: $delay;
  }
}

@mixin triangle($width, $height, $color, $direction) {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  transform: rotate(360deg);

  @if ($direction == down) {
    border-color: $color transparent transparent;
    border-width: $height ($width/2) 0 ($width/2);
  }
  @if ($direction == up) {
    border-color: transparent transparent $color;
    border-width: 0 ($width/2) $height ($width/2);
  }
  @if ($direction == right) {
    border-color: transparent transparent transparent $color;
    border-width: ($height/2) 0 ($height/2) $width;
  }
  @if ($direction == left) {
    border-color: transparent $color transparent transparent;
    border-width: ($height/2) $width ($height/2) 0;
  }
}

@mixin blender($blend-color: #000000, $blend-opacity: 0.6) {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($blend-color, $blend-opacity);
    @content;
  }
}

@function unfoldTransition ($transition) {
  // Default values
  $property: all;
  $duration: .2s;
  $easing: null; // Browser default is ease, which is what we want
  $delay: null; // Browser default is 0, which is what we want
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i)
    } @else {
      $p: nth($defaultProperties, $i)
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}

@mixin transition-multi($transitions...) {
  $unfoldedTransitions: ();
  @each $transition in $transitions {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
  }

  transition: $unfoldedTransitions;
}

// Set absolute position and clamps to al edges
@mixin absolute-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


// Spacer (dealing with margins)
@mixin space($position, $multiply:1) {
    // MARGIN
    @if ($position == top) {
      @each $breakpoint in $breakpoint-classes {
        @include breakpoint($breakpoint) {
          margin-top: map-get($spacer, $breakpoint) * $multiply;
        }
      }
    }
    @if ($position == right) {
      @each $breakpoint in $breakpoint-classes {
        @include breakpoint($breakpoint) {
          margin-right: map-get($spacer, $breakpoint) * $multiply;
        }
      }
    }
    @if ($position == bottom) {
      @each $breakpoint in $breakpoint-classes {
        @include breakpoint($breakpoint) {
          margin-bottom: map-get($spacer, $breakpoint) * $multiply;
        }
      }
    }
    @if ($position == left) {
      @each $breakpoint in $breakpoint-classes {
        @include breakpoint($breakpoint) {
          margin-left: map-get($spacer, $breakpoint) * $multiply;
        }
      }
    }
}

// Padder (dealing with padding)
@mixin padder($position, $multiply:1) {
  // PADDING
  @if ($position == top) {
    @each $breakpoint in $breakpoint-classes {
      @include breakpoint($breakpoint) {
        padding-top: map-get($spacer, $breakpoint) * $multiply;
      }
    }
  }
  @if ($position == right) {
    @each $breakpoint in $breakpoint-classes {
      @include breakpoint($breakpoint) {
        padding-right: map-get($spacer, $breakpoint) * $multiply;
      }
    }
  }
  @if ($position == bottom) {
    @each $breakpoint in $breakpoint-classes {
      @include breakpoint($breakpoint) {
        padding-bottom: map-get($spacer, $breakpoint) * $multiply;
      }
    }
  }
  @if ($position == left) {
    @each $breakpoint in $breakpoint-classes {
      @include breakpoint($breakpoint) {
        padding-left: map-get($spacer, $breakpoint) * $multiply;
      }
    }
  }
}

@mixin cubic-transition($property: transform, $time:5000ms) {
  transition: $property $time cubic-bezier(0.165, 0.840, 0.440, 1.000);
}


@mixin vertical-text() {
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);
}


@mixin svg-mask($svg-path, $svg-color:#000000) {
  content: '';
  width: 15px;
  height: 15px;
  -webkit-mask: url("../../#{$svg-path}") no-repeat 50% 50%;
  mask: url("../../#{$svg-path}") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: $svg-color;
}


@mixin play-icon($icon-path:'themes/images/svg/play-icon.svg') {
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(34, 34, 34, 0.5);
    @include transition(opacity, 200ms);
    opacity: 0;
  }

  &:after{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    margin-top: -25px;
    @include svg-mask($icon-path, #ffffff);
    @include transition(all, 300ms);
    opacity: 0.6;
  }

  &:hover {
    &:before{
      opacity: .7;
    }
    &:after{
      opacity: 1;
      transform: scale(1.1);
    }
  }
}


@mixin play-icon-css($color: #ffffff, $circle-size: 150px) {
  &:before{
    content: '';
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $circle-size;
    height: $circle-size;
    border: 1px solid $color;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    @include transition(all, 200ms);
    opacity: 1;
  }

  &:after{
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin-left: -3px;
    margin-top: -15px;
    @include triangle(15px, 30px, #ffffff, right);
    opacity: 1;
    @include transition(opacity, 200ms);
  }

  &:hover {
    &:before{
      opacity: .9;
      transform: translate(-50%, -50%) scale(0.8);
    }
    &:after{
      opacity: .9;
    }
  }
}

// FX

@mixin menu-link-fx-border($color: #000000) {
  //transition: transform 300ms ease-in-out;
  &:after {
      position: static;
      margin: 0;
      display      : block;
      content      : '';
      border-bottom: solid 1px $black;
      transform    : scaleX(0);
      transition   : transform 300ms ease-in-out;
      transform-origin: 0 50%;
  }
  &:hover {
    &:after {
      transform: scaleX(1);
    }
  }
}

;@import "sass-embedded-legacy-load-done:61";